<template>
  <table-view v-loading="loading" :show-page="false">
    <template #header>
      <view-search-form
        ref="searchFormRef"
        :queryInfo.sync="queryInfo"
        :tool-list="compHeader"
        :insert-select-all="[ 'college', 'major']"
        @on-search="renderTable" />
      <div class="header-button">
        <el-switch
          v-model="openTimer"
          active-text="动态显示"
          inactive-text="关闭"
          active-color="var(--color-success)"
          inactive-color="#ff4949"
          style="margin-top: 6px;" />
        <el-button size="small" type="success" plain @click="exportNumberCount">导出</el-button>
      </div>
    </template>
    <!-- body -->
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="按学院分配的宿舍" name="1">
          <el-table :data="roomStatusList" max-height="740" style="width: 100%; margin-bottom: 20px" border stripe>
            <el-table-column prop="collegeName" fixed label="院系" min-width="160" />
            <el-table-column label="分配情况">
              <el-table-column prop="stuNum" :label="'学生数量：'+countInfo.stuNum">
                <el-table-column prop="stuManNum" :label="'男：'+countInfo.stuManNum" width="90" />
                <el-table-column prop="stuWomanNum" :label="'女：'+countInfo.stuWomanNum" width="90" />
              </el-table-column>
              <el-table-column prop="dormitoryRoomNum" :label="'宿舍数量：'+countInfo.dormitoryRoomNum">
                <el-table-column prop="dormitoryRoomManNum" :label="'男：'+countInfo.dormitoryRoomManNum" width="90" />
                <el-table-column prop="dormitoryRoomWomanNum" :label="'女：'+countInfo.dormitoryRoomWomanNum"
                                 width="90" />
              </el-table-column>
              <el-table-column prop="dormitoryBedNum" :label="'床位数：'+countInfo.dormitoryBedNum">
                <el-table-column prop="stuNum" :label="'已启用量：'+countInfo.dormitoryBedUseNum">
                  <el-table-column prop="dormitoryBedManUseNum" :label="'男：'+countInfo.dormitoryBedManUseNum"
                                   width="90" />
                  <el-table-column prop="dormitoryBedWomanUseNum" :label="'女：'+countInfo.dormitoryBedWomanUseNum"
                                   width="90" />
                </el-table-column>
                <el-table-column prop="stuNum" :label="'未启用量：'+countInfo.dormitoryBedNoUseNum">
                  <el-table-column prop="dormitoryBedManNoUseNum" :label="'男：'+countInfo.dormitoryBedManNoUseNum"
                                   width="90" />
                  <el-table-column prop="dormitoryBedWomanNoUseNum" :label="'女：'+countInfo.dormitoryBedWomanNoUseNum"
                                   width="90" />
                </el-table-column>
              </el-table-column>
            </el-table-column>
            <el-table-column label="使用情况">
              <el-table-column prop="stayInStuNum" :label="'入住人数：'+countInfo.stayInStuNum">
                <el-table-column prop="stayInStuManNum" :label="'男：'+countInfo.stayInStuManNum" width="90" />
                <el-table-column prop="stayInStuWomanNum" :label="'女：'+countInfo.stayInStuWomanNum" width="90" />
              </el-table-column>
              <el-table-column prop="stayInRoomNum" :label="'已入住宿舍：'+countInfo.stayInRoomNum">
                <el-table-column prop="stayInRoomManNum" :label="'男：'+countInfo.stayInRoomManNum" width="90" />
                <el-table-column prop="stayInRoomWomanNum" :label="'女：'+countInfo.stayInRoomWomanNum" width="90" />
              </el-table-column>
              <el-table-column prop="stayInBedNum" :label="'已使用床位：'+countInfo.stayInBedNum">
                <el-table-column prop="stayInBedManNum" :label="'男：'+countInfo.stayInBedManNum" width="90" />
                <el-table-column prop="stayInBedWomanNum" :label="'女：'+countInfo.stayInBedWomanNum" width="90" />
              </el-table-column>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="按专业分配的宿舍" name="2">
          <el-table
            :data="roomStatusList"
            max-height="760"
            style="width: 100%; margin-bottom: 20px"
            border
            stripe
          >
            <el-table-column prop="collegeName" fixed label="院系" min-width="160" />
            <el-table-column prop="majorName" fixed label="专业" min-width="180" />
            <el-table-column label="分配情况">
              <el-table-column prop="stuNum" :label="'学生数量：'+countInfo.stuNum">
                <el-table-column prop="stuManNum" :label="'男：'+countInfo.stuManNum" width="90" />
                <el-table-column prop="stuWomanNum" :label="'女：'+countInfo.stuWomanNum" width="90" />
              </el-table-column>
              <el-table-column prop="dormitoryRoomNum" :label="'宿舍数量：'+countInfo.dormitoryRoomNum">
                <el-table-column prop="dormitoryRoomManNum" :label="'男：'+countInfo.dormitoryRoomManNum" width="90" />
                <el-table-column prop="dormitoryRoomWomanNum" :label="'女：'+countInfo.dormitoryRoomWomanNum"
                                 width="90" />
              </el-table-column>
              <el-table-column prop="dormitoryBedNum" :label="'床位数：'+countInfo.dormitoryBedNum">
                <el-table-column prop="stuNum" :label="'已启用量：'+countInfo.dormitoryBedUseNum">
                  <el-table-column prop="dormitoryBedManUseNum" :label="'男：'+countInfo.dormitoryBedManUseNum"
                                   width="90" />
                  <el-table-column prop="dormitoryBedWomanUseNum" :label="'女：'+countInfo.dormitoryBedWomanUseNum"
                                   width="90" />
                </el-table-column>
                <el-table-column prop="stuNum" :label="'未启用量：'+countInfo.dormitoryBedNoUseNum">
                  <el-table-column prop="dormitoryBedManNoUseNum" :label="'男：'+countInfo.dormitoryBedManNoUseNum"
                                   width="90" />
                  <el-table-column prop="dormitoryBedWomanNoUseNum" :label="'女：'+countInfo.dormitoryBedWomanNoUseNum"
                                   width="90" />
                </el-table-column>
              </el-table-column>
            </el-table-column>
            <el-table-column label="使用情况">
              <el-table-column prop="stayInStuNum" :label="'入住人数：'+countInfo.stayInStuNum">
                <el-table-column prop="stayInStuManNum" :label="'男：'+countInfo.stayInStuManNum" width="90" />
                <el-table-column prop="stayInStuWomanNum" :label="'女：'+countInfo.stayInStuWomanNum" width="90" />
              </el-table-column>
              <el-table-column prop="stayInRoomNum" :label="'已入住宿舍：'+countInfo.stayInRoomNum">
                <el-table-column prop="stayInRoomManNum" :label="'男：'+countInfo.stayInRoomManNum" width="90" />
                <el-table-column prop="stayInRoomWomanNum" :label="'女：'+countInfo.stayInRoomWomanNum" width="90" />
              </el-table-column>
              <el-table-column prop="stayInBedNum" :label="'已使用床位：'+countInfo.stayInBedNum">
                <el-table-column prop="stayInBedManNum" :label="'男：'+countInfo.stayInBedManNum" width="90" />
                <el-table-column prop="stayInBedWomanNum" :label="'女：'+countInfo.stayInBedWomanNum" width="90" />
              </el-table-column>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="混合宿舍" name="3">
          <el-table
            :data="roomStatusList"
            max-height="760"
            style="width: 100%; margin-bottom: 20px"
            border
            stripe
          >
            <el-table-column label="分配情况">
              <el-table-column prop="stuNum" :label="'学生数量'">
                <el-table-column prop="stuManNum" :label="'男'" />
                <el-table-column prop="stuWomanNum" :label="'女'" />
              </el-table-column>
              <el-table-column prop="mixtureDormitoryRoomNum" :label="'宿舍数量'">
                <el-table-column prop="mixtureDormitoryRoomManNum" :label="'男'" />
                <el-table-column prop="mixtureDormitoryRoomWomanNum" :label="'女 '" />
              </el-table-column>
              <el-table-column prop="mixtureDormitoryBedNum" :label="'床位数'">
                <el-table-column prop="mixtureDormitoryBedManNum" :label="'男'" />
                <el-table-column prop="mixtureDormitoryBedWomanNum" :label="'女'" />
              </el-table-column>
            </el-table-column>
            <el-table-column label="使用情况">
              <el-table-column prop="stayInStuNum" :label="'入住人数'">
                <el-table-column prop="stayInStuManNum" :label="'男'" />
                <el-table-column prop="stayInStuWomanNum" :label="'女'" />
              </el-table-column>
              <el-table-column prop="stayInRoomNum" :label="'已入住宿舍'">
                <el-table-column prop="stayInRoomManNum" :label="'男'" />
                <el-table-column prop="stayInRoomWomanNum" :label="'女'" />
              </el-table-column>
              <el-table-column prop="stayInBedNum" :label="'已使用床位'">
                <el-table-column prop="stayInBedManNum" :label="'男'" />
                <el-table-column prop="stayInBedWomanNum" :label="'女'" />
              </el-table-column>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </table-view>
</template>

<script>
import { getDormRoomStatus } from '@/api/dorm'

export default {
  data () {
    return {
      loading: false,
      roomStatusList: [],
      queryInfo: {
        grade: new Date().getFullYear(),
        campusId: null,
        collegeId: this.$store.state.login.userInfo.collegeId || '',
        inRule: 1, // 1:按院系分配 2:按专业分配 3:混合宿舍
        // mixtureFlag: 0,
        majorId: ''
      },
      activeName: '1', // 1:按院系分配 2:按专业分配 3:混合宿舍
      timer: null,
      openTimer: false,
      countInfo: {
        stuNum: null,
        dormitoryRoomNum: null,
        dormitoryBedNum: null,
        dormitoryBedUseNum: null,
        dormitoryBedNoUseNum: null,
        stayInStuNum: null,
        stayInRoomNum: null,
        stayInBedNum: null,
        mixtureDormitoryRoomNum: null,
        mixtureDormitoryBedNum: null,
        stuManNum: null,
        stuWomanNum: null,
        dormitoryRoomManNum: null,
        dormitoryRoomWomanNum: null,
        stayInStuManNum: null,
        stayInStuWomanNum: null,
        stayInRoomManNum: null,
        stayInRoomWomanNum: null,
        stayInBedManNum: null,
        stayInBedWomanNum: null,
        dormitoryBedManUseNum: null,
        dormitoryBedWomanUseNum: null,
        dormitoryBedManNoUseNum: null,
        dormitoryBedWomanNoUseNum: null
      }
    }
  },
  computed: {
    compHeader () {
      let _result = []
      switch (this.activeName) {
        case '2':
          _result = ['grade', 'college', 'major']
          break
        case '3':
          _result = ['grade']
          break
        default:
          _result = ['grade', 'college']
      }
      return _result
    }
  },
  watch: {
    openTimer (data) {
      if (data) {
        this.setTimeHandle()
      } else {
        clearInterval(this.timer)
      }
    }
  },
  async mounted () {
    await this.renderTable()
    if (this.activeName === '3') {
      // 需要请求加载collegeList,否则初始的activeName='3'时会没数据
      if (this.$refs.searchFormRef) this.$refs.searchFormRef.getCollegeList()
    }
  },
  // 监听路由销毁
  beforeDestroy () {
    clearInterval(this.timer)
  },
  methods: {
    // 切换页面
    async handleClick () {
      this.queryInfo.majorId = ''
      if (this.activeName === '3') { // 3-混合宿舍,清空 学院
        this.queryInfo.collegeId = ''
      } else {
        this.queryInfo.collegeId = this.$store.state.login.userInfo.collegeId || ''
        if (this.activeName === '2') { // 2-根据专业选择
          this.$refs.searchFormRef.getMajorList()
        }
      }
      await this.renderTable()
    },
    // 获取入住状态
    async renderTable () {
      this.queryInfo.inRule = Number(this.activeName)
      this.loading = true
      try {
        const res = await getDormRoomStatus(this.queryInfo)
        this.roomStatusList = res.data
        this.numberCountHandle(this.roomStatusList)
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    // 定时函数
    setTimeHandle () {
      this.timer = setInterval(async () => {
        await this.renderTable()
      }, 5000)
    },
    // 数量统计
    numberCountHandle (list) {
      const l1man = list.map((item) => item.stuManNum)
      const l1woman = list.map((item) => item.stuWomanNum)
      const l2man = list.map((item) => item.dormitoryRoomManNum)
      const l2woman = list.map((item) => item.dormitoryRoomWomanNum)
      const l3useman = list.map((item) => item.dormitoryBedManUseNum)
      const l3usewoman = list.map((item) => item.dormitoryBedWomanUseNum)
      const l3nouseman = list.map((item) => item.dormitoryBedManNoUseNum)
      const l3nousewoman = list.map((item) => item.dormitoryBedWomanNoUseNum)
      const l4man = list.map((item) => item.stayInStuManNum)
      const l4woman = list.map((item) => item.stayInStuWomanNum)
      const l5man = list.map((item) => item.stayInRoomManNum)
      const l5woman = list.map((item) => item.stayInRoomWomanNum)
      const l6man = list.map((item) => item.stayInBedManNum)
      const l6woman = list.map((item) => item.stayInBedWomanNum)
      const l7 = list.map((item) => item.mixtureDormitoryRoomNum)
      const l8 = list.map((item) => item.mixtureDormitoryBedNum)
      const stuNum = l1man.reduce((prev, current, index, arr) => {
        return prev + current
      }) + l1woman.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const dormitoryRoomNum = l2man.reduce((prev, current, index, arr) => {
        return prev + current
      }) + l2woman.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const dormitoryBedNum = l3useman.reduce((prev, current, index, arr) => {
        return prev + current
      }) + l3usewoman.reduce((prev, current, index, arr) => {
        return prev + current
      }) + l3nouseman.reduce((prev, current, index, arr) => {
        return prev + current
      }) + l3nousewoman.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const dormitoryBedNoUseNum = l3nouseman.reduce((prev, current, index, arr) => {
        return prev + current
      }) + l3nousewoman.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const dormitoryBedUseNum = l3useman.reduce((prev, current, index, arr) => {
        return prev + current
      }) + l3usewoman.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const stayInStuNum = l4man.reduce((prev, current, index, arr) => {
        return prev + current
      }) + l4woman.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const stayInRoomNum = l5man.reduce((prev, current, index, arr) => {
        return prev + current
      }) + l5woman.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const stayInBedNum = l6man.reduce((prev, current, index, arr) => {
        return prev + current
      }) + l6woman.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const mixtureDormitoryRoomNum = l7.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const mixtureDormitoryBedNum = l8.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const stuManNum = l1man.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const stuWomanNum = l1woman.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const dormitoryRoomManNum = l2man.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const dormitoryRoomWomanNum = l2woman.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const dormitoryBedManUseNum = l3useman.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const dormitoryBedWomanUseNum = l3usewoman.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const dormitoryBedManNoUseNum = l3nouseman.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const dormitoryBedWomanNoUseNum = l3nousewoman.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const stayInStuManNum = l4man.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const stayInStuWomanNum = l4woman.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const stayInRoomManNum = l5man.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const stayInRoomWomanNum = l5woman.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const stayInBedManNum = l6man.reduce((prev, current, index, arr) => {
        return prev + current
      })
      const stayInBedWomanNum = l6woman.reduce((prev, current, index, arr) => {
        return prev + current
      })
      this.countInfo = {
        stuNum: stuNum || 0,
        dormitoryRoomNum: dormitoryRoomNum || 0,
        dormitoryBedNum: dormitoryBedNum || 0,
        stayInStuNum: stayInStuNum || 0,
        stayInRoomNum: stayInRoomNum || 0,
        stayInBedNum: stayInBedNum || 0,
        mixtureDormitoryRoomNum: mixtureDormitoryRoomNum || 0,
        mixtureDormitoryBedNum: mixtureDormitoryBedNum || 0,
        dormitoryBedNoUseNum: dormitoryBedNoUseNum || 0,
        dormitoryBedUseNum: dormitoryBedUseNum || 0,
        stuManNum: stuManNum || 0,
        stuWomanNum: stuWomanNum || 0,
        dormitoryRoomManNum: dormitoryRoomManNum || 0,
        dormitoryRoomWomanNum: dormitoryRoomWomanNum || 0,
        stayInStuManNum: stayInStuManNum || 0,
        stayInStuWomanNum: stayInStuWomanNum || 0,
        stayInRoomManNum: stayInRoomManNum || 0,
        stayInRoomWomanNum: stayInRoomWomanNum || 0,
        stayInBedManNum: stayInBedManNum || 0,
        stayInBedWomanNum: stayInBedWomanNum || 0,
        dormitoryBedManUseNum: dormitoryBedManUseNum || 0,
        dormitoryBedWomanUseNum: dormitoryBedWomanUseNum || 0,
        dormitoryBedManNoUseNum: dormitoryBedManNoUseNum || 0,
        dormitoryBedWomanNoUseNum: dormitoryBedWomanNoUseNum || 0
      }
    },
    // 导出新生宿舍统计
    exportNumberCount () {
      this.$http.exportExcelHttp
        .exportMajorAndDormitoryStatus(this.queryInfo)
        .then((res) => {
        })
    }
  }
}
</script>
